import Repository from '../services/repository.services'
const PREFIX = 'accounting/invoicing/payment';

const state = {
    paymentList: [],
    success: false
}

const getters = {
    getPayments: (state) => state.paymentList,
}

const actions = {
    create({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getListPayments({ commit }, uuid) {
        console.log("consultando pagos en store")
        return Repository.get(`${PREFIX}/${uuid}`).then(
            response => {
                commit('SET_LIST_PARTIAL_PAYMENT', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST_PARTIAL_PAYMENT', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    update({ commit }, data) {
        return Repository.update(data.item, `${PREFIX}`).then(
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data) {
        return Repository.delete(data.item, `${PREFIX}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },

    downloadFile({ commit }, data) {
        return Repository.get(`${PREFIX}_files/${data}`).then(
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve({ data: response.data.data, status: response.status });
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST_PARTIAL_PAYMENT(state, rs) {
        console.log("SETEAR LISTA DE PAGOS ABONOS")
        state.paymentList = rs;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const payments = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import Vue from 'vue'
import Vuex from 'vuex'

import { authenticate } from './modules/auth.module'
import {admin} from './modules/admin.module'
import {messaging} from './modules/messaging.module'
import { configurations } from './modules/config.module'
import { reports } from './modules/reports.module'
import {data_public} from './modules/public.module'
import {sales} from './modules/sales.module'
import {catalogs} from './modules/catalogs.module'
import {toasts} from './modules/toasts.module'
import {clients} from './modules/clients.modules'
import {production} from './modules/production.module'
import {orders} from './modules/orders.module'
import {inspection} from './modules/inspection.module'
import {invoicing} from './modules/invoicing.module'
import {system} from './modules/system.module'
import {shopping} from './modules/shopping.module'
import {providers} from './modules/providers.module'
import {store} from './modules/store.module'
import {position} from './modules/position.module'
import {employee} from './modules/employee.module'
import {paysheet} from './modules/paysheet.module'
import {filter} from './modules/filter.module'
import {product} from './modules/product.module'
import { boarding } from './modules/boarding.module'
import { inventory } from './modules/inventory.module'
import { accountstatement } from './modules/accountstatement.module'
import { qhse } from './modules/qhse.module'
import {payments} from './modules/payment.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    toasts,
    configurations,
    messaging,
    authenticate,
    admin,
    reports,
    data_public,
    sales,
    catalogs,
    clients,
    production,
    orders,
    inspection,
    invoicing,
    system,
    shopping,
    providers,
    store,
    position,
    employee,
    paysheet,
    filter,
    product,
    boarding,
    inventory,
    accountstatement,
    qhse,
    payments
  }
})
